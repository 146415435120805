import { jsonApiAxios } from '../api';

const relationshipToTypeMap = {
  customerFeedback: 'customerFeedback',
};

export const customerFeedbackSchema = {
  attributes: ['type', 'web', 'option', 'feedback'],
  typeForAttribute: attribute => relationshipToTypeMap[attribute],
};

export function createCustomerFeedback(values: any) {
  return jsonApiAxios.post('/customerFeedback', values, {
    schema: customerFeedbackSchema,
    type: 'customerFeedback',
  });
}

export function addFeedback(id: string, values: any) {
  return jsonApiAxios.patch(`/customerFeedback/${id}`, values, {
    schema: customerFeedbackSchema,
    type: 'customerFeedback',
  });
}
