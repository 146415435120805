import React, { useState, useEffect } from 'react';
import {
  createCustomerFeedback,
  addFeedback,
} from '../../../services/resources/customerFeedback';
import { useLocation } from '@reach/router';
import TrustPilot from '../../../assets/images/trustpiloticon.png';
import { useHandleError } from '../../../services/api';
import { useIntl } from 'react-intl';
import messages from '../messages';

const emojis = [
  {
    id: '&#128546;',
    value: 1,
  },
  {
    id: '&#128546;',
    value: 2,
  },
  {
    id: '&#128528;',
    value: 3,
  },
  {
    id: '&#128522;',
    value: 4,
  },
  {
    id: '&#128525;',
    value: 5,
  },
];

const RatingPage = (props: any) => {
  const { setGoToReviewPage, reviewLink = null, toFeedbackPage } = props;
  const { formatMessage } = useIntl();
  const [selectedEmoji, setSelectedEmoji] = useState<any | null>(null);
  const [reviewId, setReviewId] = useState<any | null>(null);
  const [showTrustPilotRedirectPage, setShowTrustPilotRedirectPage] = useState<
    any
  >(false);
  const [showFeedbackPage, setShowFeedbackPage] = useState<any>(toFeedbackPage);
  const { pathname, search } = useLocation();
  const [feedback, setFeedback] = useState<string>('');
  const [counter, setCounter] = useState(5);
  const url = pathname + search;
  const [handleError] = useHandleError();

  useEffect(() => {
    if (reviewLink && showTrustPilotRedirectPage) {
      if (counter > 0) {
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        window.location.replace(reviewLink);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [counter, reviewLink, showTrustPilotRedirectPage]);

  const directToTrustPilotPage = () => {
    window.location.replace(reviewLink);
  };

  const handleEmojiClick = async (emoji: any) => {
    setSelectedEmoji(emoji);
    const values = {
      web: url,
      option: emoji.id,
      type: 'web',
    };
    try {
      const review = await createCustomerFeedback(values);
      if (review?.id) {
        localStorage.removeItem('addEmoji');
        setReviewId(review?.id);
        if (emoji.value > 3) {
          setShowTrustPilotRedirectPage(true);
        } else {
          localStorage.setItem('addFeedback', 'true');
          localStorage.setItem('feedbackId', JSON.stringify(review.id));
          setShowFeedbackPage(true);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(event.target.value); // Update state on change
  };

  const handleSaveFeedback = async () => {
    const id = reviewId || JSON.parse(localStorage.getItem('feedbackId'));

    try {
      const feedbackAdded = await addFeedback(id, { feedback });
      if (feedbackAdded?.id) {
        localStorage.removeItem('addFeedback');
        localStorage.removeItem('feedbackId');
        setGoToReviewPage(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="flex flex-col items-center" style={{ gap: '26px' }}>
      <div className="flex flex-col items-center" style={{ gap: '12px' }}>
        <span style={tilteStyles}>
          {formatMessage(messages.reviewPageHeader)}
        </span>
        <span style={subHeaderStyles}>
          {formatMessage(messages.reviewPageSubHeader)}
        </span>
      </div>
      {!showTrustPilotRedirectPage && !showFeedbackPage ? (
        <div className="flex flex-col items-center" style={{ gap: '12px' }}>
          <span style={emojiHeaderStyles}>
            {formatMessage(messages.reviewPageEmojiHeader)}
          </span>
          <div className="flex" style={{ gap: '14px' }}>
            {emojis.map(emoji => (
              <span
                key={emoji.value}
                style={emojiStyles}
                onClick={() => handleEmojiClick(emoji)}
                dangerouslySetInnerHTML={{ __html: emoji.id }}
              />
            ))}
          </div>
        </div>
      ) : showTrustPilotRedirectPage && !showFeedbackPage ? (
        <div className="flex flex-col items-center" style={{ gap: '12px' }}>
          <div className="flex">
            <span>{formatMessage(messages.reviewPageTrustPilotHeader)}</span>
            <img
              src={TrustPilot}
              alt="trust-pilot"
              style={{ marginTop: '-2px', marginLeft: '5px' }}
            />
          </div>
          <p
            className={'font-open-sans text-base mb-30 mt-0'}
            style={{ color: '#667085', fontSize: '16px' }}
          >
            {formatMessage(messages.trustPilotRedirect, {
              counter: <span>{counter}</span>,
            })}
          </p>
          <button style={buttonStyles} onClick={directToTrustPilotPage}>
            {formatMessage(messages.reviewPageTrustPilotButton)}
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center" style={{ gap: '12px' }}>
          <span>{formatMessage(messages.reviewPageFeedbackHeader)}</span>
          <textarea
            placeholder={formatMessage(messages.reviewPageFeedbackPlaceholder)}
            style={textareaStyles}
            className="custom-textarea"
            value={feedback}
            onChange={handleChange}
          />
          <button
            style={buttonStyles}
            onClick={handleSaveFeedback}
            disabled={!feedback.length}
          >
            {formatMessage(messages.reviewPageFeedbackButton)}
          </button>
        </div>
      )}
    </div>
  );
};

export default RatingPage;

const tilteStyles = {
  fontWeight: 600,
  fontSize: '30px',
  lineHeight: '36.31px',
  letterSpacing: '-2%',
  color: '#101828',
};

const subHeaderStyles = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19.36px',
  textAlign: 'center',
  color: '#667085',
};

const emojiHeaderStyles = {
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '15.4px',
  color: '#4F4F4F',
};

const emojiStyles = {
  fontSize: '26px',
  padding: '12px',
  cursor: 'pointer',
};

const buttonStyles = {
  width: '149px',
  height: '36px',
  borderRadius: '6px',
  padding: '8px 12px',
  backgroundColor: '#F36B12',
  fontWeight: 600,
  fontSize: '14px',
  color: 'white',
  // fontFamily: 'Roboto',
  lineHeight: '14px',
};

const textareaStyles = {
  width: '419px',
  height: '102px',
  borderRadius: '8px',
  border: '1.1px solid #FFFFFF',
  backgroundColor: '#F5F5F5',
  padding: '3px 10px',
};
