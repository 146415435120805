import React, { useState, useEffect } from 'react';
import messages from './messages';
import { useIntl } from 'react-intl';

const ThankYouPage = () => {
  const { formatMessage } = useIntl();
  const [counter, setCounter] = useState(3);

  // useEffect(() => {
  //   if (reviewLink) {
  //     if (counter > 0) {
  //       setTimeout(() => setCounter(counter - 1), 1000);
  //     } else {
  //       window.location.replace(reviewLink);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }
  // }, [counter, reviewLink]);

  return (
    <>
      <h1>{formatMessage(messages.thankYouTitle)}</h1>
      <p
        className={'font-open-sans text-base mb-30 mt-0'}
        style={{ color: '#667085', fontSize: '16px' }}
      >
        {formatMessage(messages.successUpdateDetails)}
      </p>
      {/* {reviewLink && (
        <p
          className={'font-open-sans text-base mb-30 mt-0'}
          style={{ color: '#667085', fontSize: '16px' }}
        >
          {formatMessage(messages.trustPilotRedirect, {
            counter: <span>{counter}</span>,
          })}
        </p>
      )} */}
    </>
  );
};

export default ThankYouPage;
