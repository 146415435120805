/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import Card from '../../components/Card';
import StepTitle from '../../components/StepTitle';
import ThankYouPage from './ThankYouPage';
import ClaimFlightInformation from './ClaimFlightInformation';
// import Subscription from './Subscription';
import questionamark from '../../assets/icons/questionamark.svg';
import personalDataIcon from '../../assets/icons/protected-data.svg';
import swirlsImg from '../../assets/images/swirls.png';
import { useIntl } from 'react-intl';
import messages from './messages';
import { useLocation } from '@reach/router';
import { parse } from 'qs';
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';
import { Form, Formik } from 'formik';
import Input from '../../components/fields/Input';
import CountrySelect from '../../components/fields/CountrySelect';
import SearchIcon from '../../assets/icons/search-icon.inline.svg';
import RadioGroup from '../../components/fields/RadioGroup';
import Loading from '../../components/Loading';
import config from '../../config.json';
// import BankDetailsOverview from './BankDetailsOverview/BankDetailsOverview';
import Button from '../../components/Button';
import TrustPilot from '../../assets/images/trustpiloticon.png';
import { useHandleError } from '../../services/api';
import { updateBankDetails } from '../../services/resources/claimBankAccounts';
import RatingPage from './Feedback/RatingPage';

const radioOptions = [
  {
    value: '0',
    label: '0€',
  },
  {
    value: '5',
    label: '5€',
  },
  {
    value: '10',
    label: '10€',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const BankDetails = () => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const [countryDetails, setCountryDetails] = useState();
  // const [accountHolder, setAccountHolder] = useState('');
  // const [ibanAccount, setIbanAccount] = useState('');
  const [otherAmount, setOtherDonateAmount] = useState<any>('');
  const [isFulfilled, setIsFulfilled] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reviewLink, setReviewLink] = useState<any>(null);
  const [goToReviewPage, setGoToReviewPage] = useState<boolean>(false);
  // const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  // const [isOverviewPage, setIsOverviewPage] = useState<boolean>(false);
  // const [bankDetailsObj, setBankDetailsObj] = useState({});
  const [initialValues, setInitialValues] = useState<any>({
    country: '',
    account_holder: '',
    address: '',
    postal_code: '',
    city: '',
    holder_address: '',
    account_number: '',
    donate_amount: '',
    other_donate_amount: '',
    bic: '',
    bank_code: '',
    bank_name: '',
    bank_city: '',
    bank_address: '',
    bank_postal_code: '',
  });
  const [handleError] = useHandleError();
  const siteSupportsDonations = config?.supports_donations;
  if (typeof document !== 'undefined') {
    const body = document.getElementsByTagName('body')[0];
    body.style.backgroundImage = `url(${swirlsImg})`;
    body.style.backgroundPosition = 'center';
    body.style.backgroundRepeat = 'no-repeat';
    body.style.backgroundSize = 'cover';
  }

  const id = parse(search, { ignoreQueryPrefix: true })?.id;
  const { data: claimInfo, get: fetchClaimBankAccounts } = useJsonApi(
    `/claimBankAccounts/${id}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          'flight.airline',
          'flight.departureAirport',
          'flight.arrivalAirport',
          'defaultCountry',
        ].join(),
      },
    }
  );

  useEffect(() => {
    if (id) {
      fetchClaimBankAccounts();
    }
  }, [id]);

  useEffect(() => {
    if (claimInfo?.[0]?.status === 'Open' || !claimInfo?.[0]?.status) {
      setCountryDetails(claimInfo?.[0]?.defaultCountry);
      setInitialValues((prevState: any) => ({
        ...prevState,
        country: claimInfo?.[0]?.defaultCountry?.id,
      }));

      setIsFulfilled(false);
    } else {
      if (
        localStorage.getItem('addEmoji') === 'true' ||
        localStorage.getItem('addFeedback') === 'true'
      ) {
        setGoToReviewPage(true);
      }
      setIsFulfilled(true);
    }
    if (claimInfo?.length) {
      setIsLoading(false);
    }
  }, [claimInfo?.length]);

  const handleSubmit = async (values: any, type?: any) => {
    if (values?.country) {
      let objByType;
      if (countryDetails?.requires_iban && countryDetails?.within_sepa) {
        objByType = {
          bankCountry: { id: values?.country },
          account_holder: values?.account_holder,
          account_number: values?.account_number,
          donate_amount: values?.other_donate_amount || values?.donate_amount,
          // create_subscription: hasSubscription,
          customer_wants_to_write_review: type === 'saveOnly' ? false : true,
        };
      } else if (
        countryDetails?.requires_iban &&
        !countryDetails?.within_sepa
      ) {
        objByType = {
          bankCountry: { id: values?.country },
          account_holder: values?.account_holder,
          account_number: values?.account_number,
          address: values?.address,
          city: values?.city,
          postal_code: values?.postal_code,
          donate_amount: values?.other_donate_amount || values?.donate_amount,
          // create_subscription: hasSubscription,
          customer_wants_to_write_review: type === 'saveOnly' ? false : true,
        };
      } else if (
        !countryDetails?.requires_iban &&
        !countryDetails?.within_sepa
      ) {
        objByType = {
          bankCountry: { id: values?.country },
          account_holder: values?.account_holder,
          account_number: values?.account_number,
          address: values?.address,
          city: values?.city,
          postal_code: values?.postal_code,
          bank_name: values?.bank_name,
          bank_code: values?.bank_code,
          bic: values?.bic,
          bank_address: values?.bank_address,
          bank_city: values?.bank_city,
          bank_postal_code: values?.bank_postal_code,
          donate_amount: values?.other_donate_amount || values?.donate_amount,
          // create_subscription: hasSubscription,
          customer_wants_to_write_review: type === 'saveOnly' ? false : true,
        };
      }

      try {
        const updatedDetails = await updateBankDetails(id, objByType);
        if (updatedDetails?.status === 'Fulfilled') {
          if (type === 'saveOnly') {
            setIsFulfilled(true);
          } else {
            setReviewLink(updatedDetails?.review_link);
            setIsFulfilled(true);
            setGoToReviewPage(true);
            localStorage.setItem('addEmoji', 'true');
          }
        }
      } catch (error) {
        handleError(error);
      }
      // setBankDetailsObj(objByType);
      // setAccountHolder(values?.account_holder);
      // setIbanAccount(values?.account_number);
      // setInitialValues((prevState: any) => ({
      //   ...prevState,
      //   ...values,
      // }));
      // setIsOverviewPage(true);
    }
  };

  const handleAddDonateAmount = (e: any) => {
    setOtherDonateAmount(e);
  };

  const checkValues = (values: any) => {
    if (countryDetails?.requires_iban && countryDetails?.within_sepa) {
      return !(
        values?.country?.length &&
        values?.account_holder?.length &&
        values?.account_number?.length
      );
    } else if (countryDetails?.requires_iban && !countryDetails?.within_sepa) {
      return !(
        values?.country?.length &&
        values?.account_holder?.length &&
        values?.account_number?.length &&
        values?.address?.length &&
        values?.city &&
        values?.postal_code
      );
    } else {
      return !(
        values?.country?.length &&
        values?.account_holder?.length &&
        values?.account_number?.length &&
        values?.address?.length &&
        values?.city?.length &&
        values?.postal_code?.length &&
        values?.bank_name?.length &&
        values?.bank_code?.length &&
        values?.bic?.length &&
        values?.bank_address?.length &&
        values?.bank_city?.length &&
        values?.bank_postal_code?.length
      );
    }
    // return true
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center mt-40">
          <Loading />
        </div>
      ) : (
        <Card style={{ marginBottom: '3.125rem' }}>
          {isFulfilled && !goToReviewPage ? (
            //   <ThankYouPage />
            // ) : isOverviewPage ? (
            //   <BankDetailsOverview
            //     flight={claimInfo?.[0]?.flight}
            //     country={countryDetails?.label}
            //     holderName={accountHolder}
            //     ibanAccount={ibanAccount}
            //     compensation={claimInfo?.[0]?.compensation_per_passenger}
            //     percentage={claimInfo?.[0]?.selling_percentage}
            //     setIsOverviewPage={setIsOverviewPage}
            //     data={bankDetailsObj}
            //     setIsFulfilled={setIsFulfilled}
            //     id={id}
            //     hasSubscription={hasSubscription}
            //   />
            <>
              <ThankYouPage />
            </>
          ) : goToReviewPage ? (
            <RatingPage
              setGoToReviewPage={setGoToReviewPage}
              reviewLink={reviewLink}
              toFeedbackPage={localStorage.getItem('addFeedback') === 'true'}
            />
          ) : (
            <>
              <StepTitle
                style={{
                  marginBottom: '0.2rem',
                  fontWeight: 600,
                  fontSize: '30px',
                }}
                className={'text-black text-24 smd:m-0'}
              >
                {formatMessage(messages.bankDetailsTitle)}
              </StepTitle>
              <p
                className={'font-open-sans text-base mb-30 mt-0'}
                style={{ color: '#667085', fontSize: '16px' }}
              >
                {formatMessage(messages.bankDetailsSubTitle)}
              </p>
              <div>
                <ClaimFlightInformation
                  flight={claimInfo?.[0]?.flight}
                  passenger_names={claimInfo?.[0]?.passenger_names}
                  selling_percentage={claimInfo?.[0]?.selling_percentage}
                  legal_procedure_percentage={
                    claimInfo?.[0]?.legal_procedure_percentage
                  }
                  additional_costs_amount={
                    claimInfo?.[0]?.additional_costs_amount
                  }
                  compensation_per_passenger={
                    claimInfo?.[0]?.compensation_per_passenger
                  }
                />
              </div>
              <Formik initialValues={initialValues}>
                {values => (
                  // eslint-disable-next-line react/jsx-no-bind
                  <Form data-testid={'form'} style={{ marginTop: '32px' }}>
                    <h2 style={{ fontSize: '20px', fontWeight: 600 }}>
                      {formatMessage(messages.cardHolderTitle)}
                    </h2>
                    <p
                      className={'font-open-sans text-base mb-30 mt-0'}
                      style={{ color: '#667085', fontSize: '16px' }}
                    >
                      {formatMessage(messages.cardHolderSubTitle)}
                    </p>
                    <CountrySelect
                      name={'country'}
                      label={messages.countryBankLabel}
                      placeholder={'select an option'}
                      // code={values?.country}
                      icon={<SearchIcon />}
                      hideIconOnValue={true}
                      prefillWithFirstOption={true}
                      countryDetails={setCountryDetails}
                      additionalQueryParams={{ page: { size: 500 } }}
                      hideSearchById={true}
                      className={'p-12'}
                    />
                    <div className="flex flex-col md:flex-row justify-between">
                      <Input
                        name={'account_holder'}
                        type="text"
                        label={messages.holderLabel}
                        className={'p-12'}
                      />
                      {countryDetails?.requires_iban || !countryDetails?.id ? (
                        <div className="info-tooltip-bank input-group sm:max-w-315">
                          <Input
                            name={'account_number'}
                            type="text"
                            className={'p-12'}
                            label={
                              <div className="flex">
                                {formatMessage(messages.ibanLabel)}{' '}
                                <img
                                  src={questionamark}
                                  style={{
                                    marginLeft: '10px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                            }
                          />
                          <div className="info-tooltiptextbank flex flex-col">
                            <h4 style={{ color: '#FFFFFF', fontWeight: 700 }}>
                              {formatMessage(messages.ibanTitle)}
                            </h4>
                            <p
                              style={{
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '13.2px',
                                color: '#FFFFFF',
                                margin: 0,
                              }}
                            >
                              {formatMessage(messages.ibanContent)}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <Input
                          name={'account_number'}
                          type="text"
                          label={messages.accountNumberLabel}
                          className={'mt-20 p-12'}
                        />
                      )}
                      <div style={{ width: '315px' }} />
                    </div>
                    {!countryDetails?.within_sepa && countryDetails?.id ? (
                      <div className="flex flex-col md:flex-row justify-start">
                        <Input
                          name={'address'}
                          type="text"
                          label={messages.holderAddressLabel}
                          className={'mt-20 p-12'}
                        />
                        <div className="flex flex-col md:flex-row justify-between sm:max-w-315 sm:ml-24">
                          <Input
                            name={'city'}
                            type="text"
                            label={messages.cityLabel}
                            className={'mt-20 p-12'}
                          />
                          <Input
                            name={'postal_code'}
                            type="text"
                            label={messages.zipCodeLabel}
                            className="mt-20 p-12"
                          />
                        </div>
                      </div>
                    ) : null}

                    {!countryDetails?.requires_iban &&
                    !countryDetails?.within_sepa &&
                    countryDetails?.id ? (
                      <div style={{ marginTop: '20px' }}>
                        <h2 style={{ fontSize: '20px', fontWeight: 600 }}>
                          {formatMessage(messages.bankDetails)}
                        </h2>
                        <p
                          className={'font-open-sans text-base mb-30 mt-0'}
                          style={{ color: '#667085', fontSize: '16px' }}
                        >
                          {formatMessage(messages.bankDetailsSubHeader)}
                        </p>
                        <Input
                          name={'bank_name'}
                          type="text"
                          label={messages.bankNameLabel}
                          className={'mt-20 p-12'}
                        />
                        <div className="flex flex-col md:flex-row justify-between">
                          <Input
                            name={'bank_code'}
                            type="text"
                            label={messages.bankCodeLabel}
                            className={'mt-20 p-12'}
                          />
                          <Input
                            name={'bic'}
                            type="text"
                            label={messages.bicCodeLabel}
                            className={'mt-20 p-12'}
                          />
                          <div style={{ width: '315px' }} />
                        </div>
                        <div className="flex flex-col md:flex-row justify-start">
                          <Input
                            name={'bank_address'}
                            type="text"
                            label={messages.bankAddessLabel}
                            className={'mt-20 p-12'}
                          />
                          <div className="flex flex-col md:flex-row justify-between sm:max-w-315 sm:ml-24">
                            <Input
                              name={'bank_city'}
                              type="text"
                              label={messages.cityLabel}
                              className={'mt-20 p-12'}

                              // inputClassName="w-34"
                            />
                            <Input
                              name={'bank_postal_code'}
                              type="text"
                              label={messages.zipCodeLabel}
                              className="ml-0 sm:ml-20 mt-20 p-12"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {siteSupportsDonations ? (
                      <div style={{ marginTop: '30px' }}>
                        <h2 style={{ fontSize: '20px', fontWeight: 600 }}>
                          {formatMessage(messages.donateCompensationTitle)}
                        </h2>
                        <p
                          className={'font-open-sans text-base mb-30 mt-0'}
                          style={{ color: '#667085', fontSize: '16px' }}
                        >
                          {formatMessage(messages.donateCompensationSubTitle)}
                        </p>
                        <div style={{ marginLeft: '-2%' }}>
                          <RadioGroup
                            headerLabel={true}
                            row={true}
                            border={false}
                            name="donate_amount"
                            options={radioOptions}
                            // eslint-disable-next-line react/jsx-no-bind
                            callback={handleAddDonateAmount}
                            hasLabel={false}
                            wrapperClassName={'sm:max-w-none mb-50'}
                          />
                        </div>
                      </div>
                    ) : null}
                    {otherAmount === 'other' ? (
                      <Input
                        name={'other_donate_amount'}
                        type="text"
                        placeholder={'€'}
                        className={'p-12'}
                      />
                    ) : null}

                    {/* Subscription Component */}
                    {/* <div
                      style={{
                        padding: '0 0.75rem 0 0.75rem',
                        marginTop: '20px',
                      }}
                    >
                      <Subscription
                        setHasSubscription={setHasSubscription}
                        hasSubscription={hasSubscription}
                      />
                    </div> */}

                    <div
                      className="flex flex-col w-full sm:max-w-315"
                      style={{ marginTop: '32px' }}
                    >
                      <span
                        style={{
                          fontSize: '14px',
                          color: '#667085',
                          width: '100vw',
                          margin: '6px 0 6px 0',
                        }}
                        className="flex"
                      >
                        {formatMessage(messages.shareNewsLabel)}{' '}
                        <img
                          src={TrustPilot}
                          alt="trust-pilot"
                          style={{ marginTop: '-2px', marginLeft: '5px' }}
                        />
                      </span>
                      <Button
                        type="submit"
                        theme="primary"
                        size="medium"
                        showIcon={false}
                        onClick={() => handleSubmit(values?.values)}
                        disabled={checkValues(values?.values)}
                      >
                        {formatMessage(messages.saveAndReview)}
                      </Button>
                      <button
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          color: '#667085',
                          margin: '12px 20px 18px 20px',
                          cursor: 'pointer',
                        }}
                        disabled={checkValues(values?.values)}
                        onClick={() => handleSubmit(values?.values, 'saveOnly')}
                      >
                        {formatMessage(messages.saveOnly)}
                      </button>
                      {/* <button
                        disabled={checkValues(values?.values)}
                        onClick={() => handleSubmit(values?.values)}
                        className="bank-details-button-confirmation"
                      >
                        Next
                      </button> */}
                      <span
                        style={{
                          display: 'flex',
                          fontSize: '14px',
                          color: '#101828',
                        }}
                      >
                        <img
                          src={personalDataIcon}
                          width={24}
                          height={24}
                          style={{ marginRight: '5px' }}
                        />{' '}
                        {formatMessage(messages.dataProtectionLabel)}
                      </span>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default BankDetails;
